<template>
  <div class="app-container">
    <div class="selectBox flex justBetween alignCenter">
      <el-input
        style="width: 300px"
        placeholder="请输入客户名称/客户ID"
        v-model="seachValue"
        @change="searchList"
        class="searchInput"
      >
        <el-button class="searchbtn" slot="append" icon="el-icon-search"></el-button>
      </el-input>
      <a :href="downloadUrl">
        <el-button><i class="fa fa-download fhd"></i> </el-button>
      </a>
    </div>
    <div class="integratable">
      <el-table :data="tableData" @sort-change="sortChange" style="width: 100%" row-key="groupId">
        <el-table-column prop="name" min-width="80" label="客户名称">
          <!-- <template slot-scope="scope">
          <el-button
            @click="toDetail(scope.row,'detail')"
            type="text"
            size="small"
            >{{scope.row.name}}</el-button
          >
        </template> -->
        </el-table-column>
        <el-table-column prop="id" min-width="80" label="客户ID"> </el-table-column>
        <!-- <el-table-column
        prop="groupCode"
      
        label="客户编号"
      >
      </el-table-column> -->
        <el-table-column prop="date" min-width="80" label="新增日期">
          <template slot-scope="scope">
            {{ moment(scope.row.date).format('YYYY-MM-DD') }}
          </template>
        </el-table-column>
        <el-table-column prop="prodList" align="left" label="产品信息" min-width="200">
          <template slot-scope="scope">
            {{ scope.row.prodList.join('、') }}
          </template>
        </el-table-column>
        <!-- <el-table-column prop="shopCount" sortable='custom' label="门店数量">
			</el-table-column>
			<el-table-column prop="agentCount" sortable='custom' label="盒子数量">
			</el-table-column>
			<el-table-column prop="cameraCount" sortable='custom' label="摄像头数量">
			</el-table-column>
			<el-table-column prop="probeCount" sortable='custom' label="探针数量">
			</el-table-column> -->
        <el-table-column label="操作" align="center" min-width="100">
          <template slot-scope="scope">
            <!-- <el-button @click="toDetail(scope.row,'edit')" type="text" size="small"
					v-if="vm.PowerData.nrcp_group_update !== 2" :disabled="vm.PowerData.nrcp_group_update === 1" size="small">修改</el-button>
					<el-button @click="toAllot(scope.row)" type="text" size="small"
					v-if="vm.PowerData.nrcp_group_update !== 2" :disabled="vm.PowerData.nrcp_group_update === 1" size="small">分配用户</el-button> -->
            <el-button type="text" size="small" @click="toUserList(scope.row)">用户列表</el-button>
            <el-button type="text" size="small" @click="handleConfig(scope.row)">客户配置</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="mt20 fr">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 30, 50]"
        :page-size="10"
        layout="total,sizes, prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import moment from 'moment'
import treemixin from 'src/mixins/tree'
import tmpColumn from 'src/mixins/templateColumn'
import { queryCustomerManageList } from 'src/api/legacy'

var httpUrl = '/newretail'
var storeHttp = '/newretail'
var storeHttpV2 = '/newretail/v2'

export default {
  mixins: [treemixin, tmpColumn],
  data() {
    return {
      seachValue: '',
      tableData: [],
      table: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
    }
  },
  computed: {
    downloadUrl() {
      return `${storeHttp}/api/customer/downloadCustomerList?keyword=${this.seachValue}&pageSize=${this.total}`
    },
  },
  methods: {
    moment,
    handleConfig(row) {
      this.$router.push({
        name: 'userConfig',
        query: {
          cid: row.id,
          cname: row.name,
        },
      })
    },
    searchList() {
      this.currentPage = 1
      this.getGroupList()
    },
    getGroupList() {
      let data = {
        keyword: this.seachValue,
        pageSize: this.pageSize,
        pageNum: this.currentPage,
      }
      const loading = this.$loading({
        text: '加载中',
        target: document.querySelector('.el-table__body'),
      })

      queryCustomerManageList(data)
        .then((res) => {
          if (res && res.status === 0) {
            this.tableData = res.data.data
            this.total = res.data.totalSize
            // this.table = res.data;
            //过滤列表
            // this.table = this.tableData.filter(e => e.groupName.includes(this.seachValue) || e.groupCode.includes(this.seachValue));
            // this.currentPage = 1;
          }
        })
        .finally(() => loading.close())
    },
    handleSizeChange(val) {
      this.currentPage = 1
      this.pageSize = val
      this.getGroupList()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.getGroupList()
    },
    overviewInit() {
      //页面初始化
      this.getGroupList()
    },
    sortChange(column) {
      const { prop } = column
      const order = column.order === 'ascending' ? 1 : -1
      this.tableData.sort((a, b) => {
        return a[prop] > b[prop] ? order : -order
      })
    },
    newMng() {
      window.location.href = './newMngment/index.html?type=new'
    },
    toDetail(row, status) {
      window.location.href = './newMngment/index.html?type=' + status + '&id=' + row.groupId
    },
    toAllot(row, status) {
      window.location.href = './allotUser/index.html?cid=' + row.cid + '&name=' + row.groupName
    },
    toUserList(row) {
      localStorage.setItem('cname', row.name)
      this.$router.push({ name: 'userList', query: { cid: row.id } })
    },
  },
  // watch: {
  // 	seachValue(val) {
  // 		this.currentPage = 1
  // 		if (val === '') {
  // 			return this.tableData = this.table
  // 		}
  // 		return this.tableData = this.table.filter(e => e.groupName.includes(val) || e.groupCode.includes(val));
  // 	}
  // },
  mounted() {
    let management = JSON.parse(sessionStorage.getItem('management'))
    this.seachValue = management === null ? this.seachValue : management.seachValue
    this.overviewInit()
    window.onbeforeunload = (e) => {
      //缓存
      let obj = {
        seachValue: this.seachValue,
      }
      sessionStorage.setItem('management', JSON.stringify(obj))
    }
  },
}
</script>
<style lang="less" scoped>
.navtitle {
  width: 100%;
  height: 80px;
  line-height: 80px;
  border-bottom: 1px solid #d1d1d1;
  font-size: 14px;
}
.titletop {
  width: 100%;
  padding: 20px 0px 10px 0;
}
.el-table__header-wrapper {
  margin-top: 10px !important;
}
.seleSeach {
  width: 204px;
  height: 30px;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
}
/* 搜索框 */
.searchInput {
  width: 200px;
}
.searchInput .el-input-group--append .el-input__inner {
  width: 171px;
}
.searchInput .el-input-group__append {
  width: 30px;
  padding: 0px;
}
.searchInput .searchbtn {
  min-width: 30px;
  width: 30px;
  padding: 0;
  margin: 0;
  height: 28px;
}
.downloadstyle {
  width: 61px;
  height: 30px;
  position: relative;
  bottom: -1px;
  line-height: 27px;
  border-radius: 2px;
  text-align: center;
  color: #000;
  font-size: 14px;
  background-color: #f4f5f7;
  z-index: 1;
}
</style>
